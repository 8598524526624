import './index.html';
import './style.scss';
import {imageLazyLoading} from "../../scripts/functions/imageLazyLoading";
import {animations} from "../../scripts/general/animations";

/**
 *
 * @param block {HTMLElement}
 * @returns {Promise<void>}
 */
const heroBlock = async (block) => {
  const video = block.querySelector(".video");
  if (video) {
    const desktopSrc = video.dataset.desktopSrc;
    const mobileSrc = video.dataset.mobileSrc;
    const desktopPoster = video.dataset.desktopPoster;
    const mobilePoster = video.dataset.mobilePoster;
    
    const addVideoSrc = () => {
      const source = window.innerWidth < 599.98 ? mobileSrc : desktopSrc;
      const poster = window.innerWidth < 599.98 ? mobilePoster : desktopPoster;
      if (video.src !== source) {
        video.setAttribute("poster", poster);
        video.src = source;
      }
    }
    
    addVideoSrc();
    
    window.addEventListener("resize", addVideoSrc);
  }
  animations(block);
  imageLazyLoading(block);
};

export default heroBlock;

