import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export async function initBlocks(container) {
  const blocks = container.querySelectorAll('[data-section-class]');
  const preLoadedBlocks = document.location.pathname === "/" ? 3 : 3;
  
  async function loadRemainingBlocks() {
    window.removeEventListener('scroll', loadRemainingBlocks);
    for (let i = 0; i < blocks.length - preLoadedBlocks; i++) {
      const block = blocks[i + preLoadedBlocks];
      import('./' + block.dataset.sectionClass).then(({default: blockScript}) => {
        block.classList.add('js-loaded')
        try {
          blockScript(block);
        } catch (e) {
          console.log(e);
        }
      })
    }
    
    // footer
    ScrollTrigger.refresh(false);
  }
  
  const footer = container.querySelector('footer');
  if (footer) {
    import('./footer_block').then(({default: blockScript}) => {
      footer.classList.add('js-loaded')
      try {
        blockScript(footer);
      } catch (e) {
        console.log(e);
      }
    })
  }
  
  for (let i = 0; i < blocks.length; i++) {
    if (i < preLoadedBlocks && blocks[i].dataset.sectionClass !== 'hero_block') {
      const block = blocks[i];
      import('./' + block.dataset.sectionClass).then(({default: blockScript}) => {
        try {
          blockScript(block);
          block.classList.add('js-loaded')
        } catch (e) {
          console.log(e);
        }
      })
    }
  }
  // await loadRemainingBlocks();
  window.scrollY > (document.location.pathname === "/" ? 1 : 50) ? loadRemainingBlocks() : window.addEventListener('scroll', loadRemainingBlocks);
}
