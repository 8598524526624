import './styles/style.scss';
import {gsap} from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import header from './blocks/header_block';
import hero from './blocks/hero_block';
import {initBlocks} from './blocks';
import {getHeightOfViewPort} from './scripts/functions/getHeightOfViewPort';
import {generateDataHover} from './scripts/functions/generateDataHover';
import {allowPageScroll, preventPageScroll} from "./scripts/functions/prevent_allowPageScroll";
import {scrollToHash} from './scripts/general/scroll-to-hash';
import {initModal} from "./scripts/general/custom-modal";
import {activePage} from "./scripts/general/active-page";

console.time('start-code')
const reInvokableFunction = async (container = document) => {
  container.querySelector('header') && await header(container.querySelector('header'));
  container.querySelector('.hero_block') && await hero(container.querySelector('.hero_block'));
  
  await initBlocks(container);
  document.body.classList.add('loaded');
  const getInTouchModal = container.querySelector("#getInTouchModal");
  const closeIcon = container.querySelector("#getInTouchModal .close");
  const satelliteCoverageModal = container.querySelector("#satelliteCoverageModal");
  const satelliteCoverageModalCloseIcon = container.querySelector("#satelliteCoverageModal .close");
  
  container.querySelectorAll(".open-contact-us-form").forEach((btn) => {
    btn.addEventListener("click", () => {
      getInTouchModal.style.display = "block";
      satelliteCoverageModal.style.display = "none";
      preventPageScroll();
    })
  })
  
  container.querySelectorAll(".open-sat-coverage-modal").forEach((btn) => {
    btn.addEventListener("click", () => {
      satelliteCoverageModal.style.display = "block";
      preventPageScroll();
    })
  })
  
  if (closeIcon) {
    closeIcon.onclick = function () {
      getInTouchModal.style.display = "none";
      allowPageScroll();
    }
  }
  
  if (satelliteCoverageModalCloseIcon) {
    satelliteCoverageModalCloseIcon.addEventListener("click", () => {
      satelliteCoverageModal.style.display = "none";
      allowPageScroll();
    })
  }
  
  window.addEventListener("click", (event) => {
    if (event.target === getInTouchModal) {
      getInTouchModal.style.display = "none";
      allowPageScroll();
    }
    if (event.target === satelliteCoverageModal) {
      satelliteCoverageModal.style.display = "none";
      allowPageScroll();
    }
  })
  generateDataHover(container);
  scrollToHash(container);
  getHeightOfViewPort(container);
  activePage(container);
  ScrollTrigger.refresh(false);
};
let loaded = false;

async function onLoad() {
  gsap.config({
    nullTargetWarn: false,
  });
  if ((document.readyState === 'complete' || document.readyState === 'interactive') && !loaded) {
    loaded = true;
    initModal();
    gsap.registerPlugin(ScrollTrigger);
    
    await reInvokableFunction();
    document.body.classList.add('loaded');
    //region force page to scroll to element if url has hash
    let currentURL = window.location.href;
    let blockID = currentURL.split('#');
    if (blockID && blockID[1]) {
      let block = document.querySelector('#' + blockID[1]);
      if (block) {
        document.body.classList.remove('loaded');
        setTimeout(() => {
          window.scrollTo(0, (block.getBoundingClientRect().top + window.scrollY - window.headerSticky ? window.headerSticky : 93))
          document.body.classList.add('loaded');
        }, 400)
      }
    }
    //endregion force page to scroll to element if url has hash
  }
}

if (document.readyState === 'loading') {  // Loading hasn't finished yet
  document.addEventListener('DOMContentLoaded', onLoad);
} else {  // `DOMContentLoaded` has already fired
  onLoad();
}

